
































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { UpdateMasterCategory } from '@/store/mastercategories/types'
import MButton from '@/components/common/MButton.vue'
import MModalCard from '@/components/common/MModalCard.vue'
import MTextField from '@/components/common/MTextField.vue'
import { ButtonType } from './common/types'

@Component({
  components: { MButton, MModalCard, MTextField }
})
export default class UpdateMasterCategoryForm extends Vue {
  @Prop() value!: UpdateMasterCategory
  @Prop() onUpdateMasterCategory!: Function
  @Prop(Boolean) showDelete!: boolean
  @Prop() onDeleteMasterCategory!: Function
  @Prop() onCancel!: Function

  ButtonType = ButtonType

  get newValue(): UpdateMasterCategory {
    return this.value
  }

  mounted(): void {
    const nameInput = this.$refs.nameInput as any
    this.$nextTick(() => {
      nameInput.focus()
    })
  }
}

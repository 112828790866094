






































































































































































































































































import { defaultMonthlyBudgetApi } from '@/store/budgets/BudgetApi'
import { Watch, Component } from 'vue-property-decorator'
import {
  MonthlyBudget,
  CategoryBudget,
  UpdateMonthlyBudget
} from '../store/budgets/types'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import moment from 'moment'
import { categoryModule } from '@/store/categories/module'
import { Category, UpdateCategory } from '@/store/categories/types'
import { masterCategoryModule } from '@/store/mastercategories/module'
import {
  MasterCategory,
  MoveMasterCategoriesDto,
  UpdateMasterCategory
} from '@/store/mastercategories/types'
import 'moment/locale/de'
import { DateRange } from '../types'
import BalanceLabel from '@/components/BalanceLabel.vue'
import _ from 'lodash'
import UpdateCategoryForm from '@/components/UpdateCategoryForm.vue'
import UpdateMasterCategoryForm from '@/components/UpdateMasterCategoryForm.vue'
import BudgetAmountInput from '@/components/BudgetAmountInput.vue'
import MButton from '@/components/common/MButton.vue'
import GlobalMixin from '../mixins/GlobalMixin.vue'
import { mixins } from 'vue-class-component'
import { ButtonType } from '@/components/common/types'

@Component({
  components: {
    DefaultLayout,
    BalanceLabel,
    UpdateCategoryForm,
    UpdateMasterCategoryForm,
    BudgetAmountInput,
    MButton
  }
})
export default class MonthlyBudgetView extends mixins(GlobalMixin) {
  currentMonthlyBudget: MonthlyBudget | null = null
  currentMonthlyBudgetCopy: MonthlyBudget | null = null
  categoryBudgetIdBeingEdited: string | null = null
  categoryBudgetBeingEdited: CategoryBudget | null = null

  categoryBeingEdited: Category | null = null
  masterCategoryBeingEdited: MasterCategory | null = null

  categories = categoryModule
  masterCategories = masterCategoryModule
  loading: boolean = false

  rowDragStart: MasterCategory | null = null
  rowDragStartIndex: number = -1
  rowDragOverIndex: number = -1

  ButtonType = ButtonType

  async initialize(): Promise<void> {
    this.loading = true
    return defaultMonthlyBudgetApi()
      .fetchMonthlyBudget(this.currentBudgetId, this.currentYearMonth)
      .then(monthlyBudget => {
        this.currentMonthlyBudget = monthlyBudget
        this.currentMonthlyBudgetCopy = _.cloneDeep(monthlyBudget)
        this.loading = false
      })
  }

  mounted(): void {
    this.initialize()
    const locale = window.navigator.language
    if (locale) {
      moment().locale(locale)
    }
  }

  @Watch('$route')
  onRouteChange(newVal: any): void {
    this.initialize()
  }

  get currentYearMonth(): string {
    return this.$route.params.yearMonth
  }

  get currentBudgetId(): string {
    return this.$route.params.budgetId
  }

  get currentDate(): Date {
    return moment(this.currentYearMonth, 'YYYY-MM').toDate()
  }

  get currentMonth(): string {
    return moment(this.currentYearMonth, 'YYYY-MM').format('MMMM')
  }

  get currentYearMonthTitle(): string {
    return moment(this.currentYearMonth, 'YYYY-MM').format('MMM YYYY')
  }

  get allCategories(): Category[] {
    return this.categories.categories.filter(category => {
      return (
        category.id !== 'INCOME_FOR_THIS_MONTH' &&
        category.id !== 'INCOME_FOR_NEXT_MONTH'
      )
    })
  }

  get allMasterCategories(): MasterCategory[] {
    return this.masterCategories.masterCategories
  }

  get allVisibleMasterCategories(): MasterCategory[] {
    return this.allMasterCategories.filter(mc => mc.id !== 'HIDDEN_CATEGORIES')
  }

  get initiallyOpenMasterCategories(): string[] {
    return this.allVisibleMasterCategories.map(mc => mc.id || '')
  }

  get categoriesOfMaster(): Function {
    return (masterCategoryId: string) => {
      return this.allCategories.filter(
        category =>
          category.masterCategoryId &&
          category.masterCategoryId === masterCategoryId
      )
    }
  }

  isCategoryBudgetBeingEdited(categoryId: string): boolean {
    return categoryId === this.categoryBudgetIdBeingEdited
  }

  isCategoryBeingEdited(category: Category): boolean {
    return this.categoryBeingEdited
      ? category.id === this.categoryBeingEdited.id
      : false
  }

  onCategoryClicked(category: Category): void {
    this.categoryBeingEdited = _.cloneDeep(category)
  }

  onMasterCategoryClicked(masterCategory: MasterCategory): void {
    if (masterCategory.id !== 'HIDDEN_CATEGORIES') {
      this.masterCategoryBeingEdited = _.cloneDeep(masterCategory)
    }
  }

  onCategoryBudgetClicked(category: Category): void {
    this.categoryBudgetIdBeingEdited = category.id || null

    const categoryBudget = this.categoryBudget(this.categoryBudgetIdBeingEdited)
    if (!categoryBudget) {
      return
    }

    this.categoryBudgetBeingEdited = categoryBudget

    this.$nextTick(() => {
      const budgetedInput = this.$refs.budgetedInput as HTMLInputElement[]
      budgetedInput[0].focus()
      if (this.isMobile) {
        this.scrollBudgetedAmountToTop()
      }
    })
  }

  scrollBudgetedAmountToTop(): void {
    const budgetAmountInput = document.getElementById('budget-amount-input')
    if (!budgetAmountInput || !budgetAmountInput.parentElement) {
      return
    }
    const budgetAmountColumn = budgetAmountInput.parentElement

    const scrollingParent = document.getElementById('monthly-budget-scroller')

    if (scrollingParent && scrollingParent.parentElement) {
      scrollingParent.parentElement.scrollTo(0, budgetAmountColumn.offsetTop)
    }
  }

  async onFinishCategoryEditing(): Promise<void> {
    if (
      this.categoryBeingEdited &&
      this.categoryBeingEdited.id &&
      this.categoryBeingEdited.name &&
      this.categoryBeingEdited.masterCategoryId
    ) {
      const updateDto = new UpdateCategory(
        this.categoryBeingEdited.id,
        this.categoryBeingEdited.name,
        this.categoryBeingEdited.masterCategoryId
      )

      this.categoryBeingEdited = null

      return this.categories.updateCategory(updateDto).then(() => {
        this.categories.loadAllCategories()
        this.masterCategories.loadAllMasterCategories()
        this.initialize()
      })
    }
  }

  async onFinishMasterCategoryEditing(): Promise<MasterCategory[] | void> {
    if (
      this.masterCategoryBeingEdited &&
      this.masterCategoryBeingEdited.id &&
      this.masterCategoryBeingEdited.name &&
      this.masterCategoryBeingEdited.order
    ) {
      const updateDto = new UpdateMasterCategory(
        this.masterCategoryBeingEdited.id,
        this.masterCategoryBeingEdited.name,
        this.masterCategoryBeingEdited.order
      )

      this.masterCategoryBeingEdited = null

      return this.masterCategories.updateMasterCategory(updateDto).then(() => {
        this.masterCategories.loadAllMasterCategories()
        this.initialize()
      })
    }
  }

  async onDeleteMasterCategory(): Promise<void> {
    const idToDelete = this.masterCategoryBeingEdited?.id
    if (idToDelete) {
      this.masterCategories.deleteMasterCategory(idToDelete)
    }
    this.categoryBeingEdited = null
    this.masterCategoryBeingEdited = null
  }

  get canDeleteMasterCategory(): boolean {
    if (!this.masterCategoryBeingEdited?.id) {
      return false
    }
    return !this.categories.categories.some(
      category =>
        category.masterCategoryId === this.masterCategoryBeingEdited?.id
    )
  }

  onCancelAnyCategoryEditing(): void {
    this.categoryBeingEdited = null
    this.masterCategoryBeingEdited = null
  }

  async onFinishBudgetedEditing(): Promise<void> {
    if (!this.currentMonthlyBudget) {
      return
    }

    this.categoryBudgetIdBeingEdited = null
    this.categoryBudgetBeingEdited = null

    if (_.isEqual(this.currentMonthlyBudget, this.currentMonthlyBudgetCopy)) {
      return
    }

    const updateDto = UpdateMonthlyBudget.of(this.currentMonthlyBudget)
    if (updateDto) {
      return defaultMonthlyBudgetApi()
        .updateMonthlyBudget(this.currentBudgetId, updateDto)
        .then(() => this.initialize())
    } else {
      return Promise.resolve()
    }
  }

  onCancelBudgetedEditing(): void {
    this.categoryBudgetIdBeingEdited = null
    this.categoryBudgetBeingEdited = null
  }

  async toggleRetainOverspending(categoryId: string): Promise<void> {
    const categoryBudget = this.categoryBudget(categoryId)
    if (!this.currentMonthlyBudget || !categoryBudget) {
      return
    }
    const updateDto = UpdateMonthlyBudget.of(this.currentMonthlyBudget)
    if (!updateDto) {
      return
    }

    categoryBudget.retainOverspending = !categoryBudget.retainOverspending
    updateDto.categoryBudgets[categoryId] = categoryBudget

    return defaultMonthlyBudgetApi()
      .updateMonthlyBudget(this.currentBudgetId, updateDto)
      .then(() => this.initialize())
  }

  categoryBudget(categoryId: string | null): CategoryBudget | null {
    if (
      !categoryId ||
      !this.currentMonthlyBudget ||
      !this.currentMonthlyBudget.categoryBudgets
    ) {
      return null
    }
    const categoryBudgets = this.currentMonthlyBudget.categoryBudgets as any
    if (!categoryBudgets[categoryId]) {
      categoryBudgets[categoryId] = new CategoryBudget()
    }
    return categoryBudgets[categoryId]
  }

  masterCategoryBudget(masterCategoryId: string): CategoryBudget {
    const result = new CategoryBudget()
    this.categoriesOfMaster(masterCategoryId)
      .filter((category: Category) => category.id !== null)
      .map((category: Category) => this.categoryBudget(category.id || null))
      .forEach((categoryBudget: CategoryBudget) => {
        if (categoryBudget) {
          result.budgeted += categoryBudget.budgeted
          result.activity += categoryBudget.activity
          result.available += categoryBudget.available
        }
      })
    return result
  }

  budgeted(categoryId: string): number {
    const categoryBudget = this.categoryBudget(categoryId)
    return categoryBudget ? categoryBudget.budgeted : 0
  }

  masterBudgeted(masterCategoryId: string): number {
    const masterCategoryBudget = this.masterCategoryBudget(masterCategoryId)
    return masterCategoryBudget ? masterCategoryBudget.budgeted : 0
  }

  activity(categoryId: string): number {
    const categoryBudget = this.categoryBudget(categoryId)
    return categoryBudget ? categoryBudget.activity : 0
  }

  masterActivity(masterCategoryId: string): number {
    const masterCategoryBudget = this.masterCategoryBudget(masterCategoryId)
    return masterCategoryBudget ? masterCategoryBudget.activity : 0
  }

  available(categoryId: string): number {
    const categoryBudget = this.categoryBudget(categoryId)
    return categoryBudget ? categoryBudget.available : 0
  }

  isRetainOverspending(categoryId: string): boolean {
    const categoryBudget = this.categoryBudget(categoryId)
    if (!categoryBudget) {
      return false
    }
    return categoryBudget.available < 0 && categoryBudget.retainOverspending
  }

  masterAvailable(masterCategoryId: string): number {
    const masterCategoryBudget = this.masterCategoryBudget(masterCategoryId)
    return masterCategoryBudget ? masterCategoryBudget.available : 0
  }

  onDateChanged(newDate: Date): void {
    const newYearMonth = moment(newDate).format('YYYY-MM')
    this.$router.push({
      name: 'monthly-budget',
      params: { yearMonth: newYearMonth }
    })
  }

  onMonthSelectorClicked(): void {
    const monthpicker = this.$refs.monthpicker as any
    monthpicker.toggle()
  }

  onNextMonthClicked(): void {
    this.onDateChanged(
      moment(this.currentDate)
        .add(1, 'month')
        .toDate()
    )
  }

  onPreviousMonthClicked(): void {
    this.onDateChanged(
      moment(this.currentDate)
        .subtract(1, 'month')
        .toDate()
    )
  }

  get currentMonthDateRange(): DateRange {
    const result = new DateRange()
    result.from = moment(this.currentDate)
      .startOf('month')
      .toDate()
    result.until = moment(this.currentDate)
      .endOf('month')
      .toDate()
    return result
  }

  onCategoryActivityClicked(category: Category): void {
    const fromDate = this.currentMonthDateRange.from
    const from = fromDate ? moment(fromDate).format('YYYY-MM-DD') : undefined
    const untilDate = this.currentMonthDateRange.until
    const until = untilDate ? moment(untilDate).format('YYYY-MM-DD') : undefined

    this.$router.push({
      name: 'transactions',
      query: Object.assign({}, this.$route.query, {
        from: from,
        until: until,
        filterCategories: category.id
      })
    })
  }

  get availableToBudgetLastMonthLabel(): string {
    if (
      this.currentMonthlyBudget &&
      this.currentMonthlyBudget.availableToBudgetLastMonth &&
      this.currentMonthlyBudget.availableToBudgetLastMonth < 0
    ) {
      return 'Overbudgeted last month'
    }

    return 'Not budgeted last month'
  }

  toggleDetails(masterCategory: MasterCategory): void {
    ;(this.$refs.table as any).toggleDetails(masterCategory)
  }

  get monthpickerSlot(): string {
    return this.isMobile ? 'action-bar' : 'actions'
  }

  onDragStart(payload: {
    row: MasterCategory
    event: DragEvent
    index: number
  }): void {
    if (payload.row.order === 0) {
      payload.event.preventDefault()
    } else {
      this.rowDragStartIndex = payload.index
      this.rowDragStart = payload.row
      if (payload?.event?.dataTransfer) {
        payload.event.dataTransfer.effectAllowed = 'copy'
      }
    }
  }

  onDragOver(payload: {
    row: MasterCategory
    event: DragEvent
    index: number
  }): void {
    this.rowDragOverIndex = payload.index
    if (payload?.event?.dataTransfer && payload.row.order !== 0) {
      payload.event.dataTransfer.dropEffect = 'copy'
    }
    payload.event.preventDefault()
  }

  onDragLeave(payload: {
    row: MasterCategory
    event: DragEvent
    index: number
  }): void {
    this.rowDragOverIndex = -1
  }

  onDragEnd(payload: {
    row: MasterCategory
    event: DragEvent
    index: number
  }): void {
    this.rowDragOverIndex = -1
    this.rowDragStartIndex = -1
    this.rowDragStart = null
  }

  onDrop(payload: {
    row: MasterCategory
    event: DragEvent
    index: number
  }): void {
    const oldOrder = this.rowDragStart?.order
    const newOrder = payload.row.order
    if (oldOrder && newOrder && oldOrder !== newOrder) {
      const dto = new MoveMasterCategoriesDto(oldOrder, newOrder)
      this.masterCategories.moveMasterCategories(dto)
    }
  }

  rowClass(masterCategory: MasterCategory, index: number): string | null {
    if (this.rowDragStartIndex === this.rowDragOverIndex) {
      return null
    }
    if (index > this.rowDragStartIndex) {
      if (index - 1 === this.rowDragOverIndex) {
        return 'row-drag-over'
      }
    } else if (index < this.rowDragStartIndex) {
      if (index === this.rowDragOverIndex) {
        return 'row-drag-over'
      }
    }

    return null
  }
}

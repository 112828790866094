import axios from '@/backend/axios'
import { MonthlyBudget, UpdateMonthlyBudget, QuickBudget } from './types'

export default interface MonthlyBudgetApi {
  fetchMonthlyBudget(
    budgetId: string,
    yearMonth: string
  ): Promise<MonthlyBudget>
  updateMonthlyBudget(
    budgetId: string,
    updateMonthlyBudget: UpdateMonthlyBudget
  ): Promise<MonthlyBudget>
  fetchQuickBudget(
    budgetId: string,
    yearMonth: string,
    categoryId: string
  ): Promise<QuickBudget>
}

class RealMonthlyBudgetApi implements MonthlyBudgetApi {
  private httpClient: any

  public constructor(httpClient: any) {
    this.httpClient = httpClient
  }

  public async fetchMonthlyBudget(
    budgetId: string,
    yearMonth: string
  ): Promise<MonthlyBudget> {
    const response = await this.httpClient.get(
      `${budgetId}/budgets/${yearMonth}`
    )
    return response.data as MonthlyBudget
  }

  async updateMonthlyBudget(
    budgetId: string,
    budget: UpdateMonthlyBudget
  ): Promise<MonthlyBudget> {
    const response = await this.httpClient.put(`${budgetId}/budgets/`, budget)
    return response.data as MonthlyBudget
  }

  async fetchQuickBudget(
    budgetId: string,
    yearMonth: string,
    categoryId: string
  ): Promise<QuickBudget> {
    const response = await this.httpClient.get(
      `${budgetId}/quick-budget/${yearMonth}/${categoryId}`
    )
    return response.data as QuickBudget
  }
}

export const createMonthlyBudgetApi = (httpClient: any): MonthlyBudgetApi => {
  return new RealMonthlyBudgetApi(httpClient)
}

export const defaultMonthlyBudgetApi = (): MonthlyBudgetApi => {
  return createMonthlyBudgetApi(axios)
}

import { Entity } from '@/types'

export class MasterCategory implements Entity {
  id?: string
  name?: string
  order?: number

  constructor(id?: string, name?: string, order?: number) {
    this.id = id
    this.name = name
    this.order = order
  }
}

export class UpdateMasterCategory {
  id: string
  name: string
  order: number

  constructor(id: string, name: string, order: number) {
    this.id = id
    this.name = name
    this.order = order
  }
}

export class MoveMasterCategoriesDto {
  originalOrder: number
  newOrder: number

  constructor(originalOrder: number, newOrder: number) {
    this.originalOrder = originalOrder
    this.newOrder = newOrder
  }
}




















































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { UpdateCategory } from '@/store/categories/types'
import { MasterCategory } from '@/store/mastercategories/types'
import MButton from '@/components/common/MButton.vue'
import MModalCard from '@/components/common/MModalCard.vue'
import MTextField from '@/components/common/MTextField.vue'
import { ButtonType } from './common/types'

@Component({
  components: { MButton, MModalCard, MTextField }
})
export default class UpdateCategoryForm extends Vue {
  @Prop() value!: UpdateCategory
  @Prop() masterCategories!: MasterCategory[]
  @Prop() onUpdateCategory!: Function
  @Prop() onCancel!: Function

  ButtonType = ButtonType

  get newValue(): UpdateCategory {
    return this.value
  }

  mounted(): void {
    const nameInput = this.$refs.nameInput as any
    this.$nextTick(() => {
      nameInput.focus()
    })
  }

  onHideClicked(): void {
    this.newValue.masterCategoryId = 'HIDDEN_CATEGORIES'
    this.onUpdateCategory()
  }
}

export class CategoryBudget {
  budgeted: number = 0
  activity: number = 0
  available: number = 0
  retainOverspending: boolean = false
}

export class MonthlyBudget {
  yearMonth?: string
  categoryBudgets?: Map<string, CategoryBudget>
  incomeForThisMonth?: number
  budgetedThisMonth?: number
  overspentLastMonth?: number
  availableToBudget?: number
  availableToBudgetLastMonth?: number
}

export class UpdateMonthlyBudget {
  yearMonth: string
  categoryBudgets: any

  constructor(yearMonth: string, categoryBudgets: any) {
    this.yearMonth = yearMonth
    this.categoryBudgets = categoryBudgets
  }

  public static of(
    monthlyBudget: MonthlyBudget
  ): UpdateMonthlyBudget | undefined {
    if (!monthlyBudget.yearMonth || !monthlyBudget.categoryBudgets) {
      return undefined
    }

    const categoryBudgets = {} as any
    for (const entry of Object.entries(monthlyBudget.categoryBudgets)) {
      categoryBudgets[entry[0]] = {
        budgeted: entry[1].budgeted,
        retainOverspending: entry[1].retainOverspending
      }
    }
    return new UpdateMonthlyBudget(monthlyBudget.yearMonth, categoryBudgets)
  }
}

export class QuickBudget {
  budgetedPreviousMonth: number
  activityLastMonth: number

  constructor(budgetedPreviousMonth: number, activityLastMonth: number) {
    this.budgetedPreviousMonth = budgetedPreviousMonth
    this.activityLastMonth = activityLastMonth
  }
}







































import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import GlobalMixin from '../mixins/GlobalMixin.vue'
import AmountInput from '@/components/AmountInput.vue'
import BalanceLabel from '@/components/BalanceLabel.vue'
import MButton from '@/components/common/MButton.vue'
import { defaultMonthlyBudgetApi } from '@/store/budgets/BudgetApi'
import moment from 'moment'
import { ButtonType } from './common/types'

@Component({
  components: { AmountInput, BalanceLabel, MButton }
})
export default class BudgetAmountInput extends mixins(GlobalMixin) {
  @Prop() value!: number
  @Prop() disabled!: boolean
  @Prop() categoryId!: string
  @Prop() yearMonth!: string

  budgetedPreviousMonth: number | null = null
  spentPreviousMonth: number | null = null

  ButtonType = ButtonType

  get newValue(): number {
    return this.value
  }

  set newValue(newValue: number) {
    this.$emit('input', newValue)
  }

  mounted(): void {
    defaultMonthlyBudgetApi()
      .fetchQuickBudget(this.currentBudgetId, this.yearMonth, this.categoryId)
      .then(quickBudget => {
        this.budgetedPreviousMonth = quickBudget.budgetedPreviousMonth
        this.spentPreviousMonth = quickBudget.activityLastMonth
          ? -quickBudget.activityLastMonth
          : 0
      })
  }

  focus(): void {
    const amountInput = this.$refs.amountInput as HTMLInputElement
    amountInput.focus()
  }

  onBlur(blurEvent?: FocusEvent): void {
    setTimeout(() => {
      this.$emit('blur', blurEvent)
    }, 100)
  }

  onBudgetPreviousMonthClicked(): void {
    if (this.budgetedPreviousMonth !== null) {
      this.newValue = this.budgetedPreviousMonth
      this.$nextTick(() => this.$emit('blur'))
    }
  }

  onSpentPreviousMonthClicked(): void {
    if (this.spentPreviousMonth !== null) {
      this.newValue = this.spentPreviousMonth
      this.$nextTick(() => this.$emit('blur'))
    }
  }

  get isLoading(): boolean {
    return this.budgetedPreviousMonth === null
  }

  get lastMonthLabel(): string {
    return moment(this.yearMonth, 'YYYY-MM')
      .subtract(1, 'month')
      .format('MMM YYYY')
  }
}
